.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-left: 35%;
    margin-top: 30px;
    margin-right: 20px;

    .project-card {
        background-repeat: no-repeat;
        height: 300px;
        border-radius: 8px;
        position: relative;
        display: flex;
        align-items: flex-end;
        padding: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: #fff;

        .project-info {
            background-color: #2AFC98;
            opacity: 0.8;
            color: #525252;
            padding: 10px;
            border-radius: 8px;
            width: 100%;

            h5 {
                font-size: 12px;
                font-family: sans-serif;
                margin-top: 0;
            }
        }
    }

    .project-card-cover {
        background-size: cover; // Ensures the image covers the entire card
        background-position: center;
    }
    
    .project-card-contain {
        background-size: contain; // Ensures the whole image fits within the card
        background-color: #fff; // Background color for any space not covered by the image
        background-position: 50% calc(50% - 20px);
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden; // Prevents content from spilling outside
}

.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-title {
    margin-left: 20px; // Ensures title does not overlap close button
    font-size: 48px;
    font-family: 'Coolvetica';
    align-self: flex-start;
}

.modal-description {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 20px; // Space between text and image
    margin-left: 20px;
}

.modal-text {
    flex: 3; // Takes more space than the image
    overflow-y: auto; // Allows scrolling for long texts
    font-family: sans-serif;
    padding-right: 20px;
    font-size: 20px;
    font-weight: 700;
}

.modal-image {
    flex: 1;
    max-width: 30%;
    max-height: 70%; // Maintains aspect ratio
    object-fit: cover;
    padding-right: 30px;
}

.learn-more-btn {
    align-self: flex-start; // Aligns the button to the left
    position: absolute;
    padding: 10px 20px;
    background-color: #2AFC98;
    color: #525252;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    margin-top: 43%;
    margin-bottom: 10px;
    font-size: 24px;
}
